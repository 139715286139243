<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Edit General Info</h2>
      <r-third-level-menu />
    </template>
    <template #page-content>
      <div id="edit-personal-info">
        <div
          id="general-information"
          v-if="!isLoadingCurrentUser && currentUser != null"
        >
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(save)">
              <div
                class="buttons"
                style="border-bottom: 0.1px solid rgba(100, 100, 100, 0.1)"
              >
                <b-button
                  type="is-primary"
                  outlined
                  icon-left="close"
                  :disabled="isRequesting"
                  @click="$router.push('/settings/personal-info')"
                >
                  Cancel
                </b-button>
                <b-button
                  type="is-primary"
                  icon-left="check"
                  native-type="submit"
                  :loading="isRequesting"
                >
                  Save
                </b-button>
              </div>
              <div class="columns is-multiline">
                <div class="column is-4">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <p class="is-size-3 has-text-weight-bold">
                        General Information
                      </p>
                      <ProfilePicture
                        :user-id="currentUser.id"
                        :profile-picture-url="
                          formatProfilePictureUrl(currentUser)
                        "
                      />
                    </div>
                    <div class="column is-12-desktop is-12-mobile is-12-tablet">
                      <div class="columns is-multiline">
                        <div
                          class="column is-4-desktop is-4-tablet is-12-mobile"
                        >
                          <ValidationProvider
                            name="first name"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <b-field
                              label="First Name"
                              :type="errors[0] && 'is-danger'"
                              :message="errors[0]"
                            >
                              <b-input
                                v-model="firstName"
                                placeholder="Hannah"
                                expanded
                              ></b-input>
                            </b-field>
                          </ValidationProvider>
                        </div>

                        <div
                          class="column is-4-desktop is-4-tablet is-12-mobile"
                        >
                          <ValidationProvider
                            name="middle name"
                            v-slot="{ errors }"
                          >
                            <b-field
                              label="Middle Name"
                              :type="errors[0] && 'is-danger'"
                              :message="errors[0]"
                            >
                              <b-input
                                v-model="middleName"
                                placeholder="Jane"
                                expanded
                              ></b-input>
                            </b-field>
                          </ValidationProvider>
                        </div>

                        <div
                          class="column is-4-desktop is-4-tablet is-12-mobile"
                        >
                          <ValidationProvider
                            name="last name"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <b-field
                              label="Last Name"
                              :type="errors[0] && 'is-danger'"
                              :message="errors[0]"
                            >
                              <b-input
                                v-model="lastName"
                                placeholder="Khanwald"
                                expanded
                              ></b-input>
                            </b-field>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>

                    <div class="column is-4-desktop is-6-tablet is-12-mobile">
                      <ValidationProvider
                        name="Birth Place"
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-field
                          label="Birth Place"
                          :type="errors[0] && 'is-danger'"
                          :message="errors[0]"
                        >
                          <b-input
                            placeholder="Bandung"
                            v-model="birthPlace"
                          ></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>

                    <div class="column is-8-desktop is-6-tablet is-12-mobile">
                      <ValidationProvider
                        name="Birth Date"
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-field
                          label="Birth Date"
                          :type="errors[0] && 'is-danger'"
                          :message="errors[0]"
                        >
                          <b-datepicker
                            v-model="birthDate"
                            placeholder="Type or select a date..."
                            icon="calendar-today"
                            :date-formatter="dateFormatter"
                            editable
                          />
                        </b-field>
                      </ValidationProvider>
                    </div>

                    <div class="column is-4-desktop is-4-tablet is-12-mobile">
                      <ValidationProvider
                        name="gender"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-field
                          label="Gender"
                          :type="errors[0] && 'is-danger'"
                          :message="errors[0]"
                        >
                          <b-select
                            placeholder="Gender"
                            expanded
                            v-model="gender"
                          >
                            <option
                              v-for="option in genders"
                              :value="option.value"
                              :key="option.value"
                            >
                              {{ option.label }}
                            </option>
                          </b-select>
                        </b-field>
                      </ValidationProvider>
                    </div>

                    <div class="column is-4-desktop is-4-tablet is-12-mobile">
                      <ValidationProvider
                        name="nationality"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-field
                          label="Nationality"
                          :type="errors[0] && 'is-danger'"
                          :message="errors[0]"
                        >
                          <b-input
                            v-model="nationality"
                            placeholder="Indonesia"
                            expanded
                          ></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>

                    <div class="column is-4-desktop is-4-tablet is-12-mobile">
                      <ValidationProvider
                        name="Blood Type"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-field
                          label="Blood Type"
                          :type="errors[0] && 'is-danger'"
                          :message="errors[0]"
                        >
                          <b-select
                            placeholder="Blood Type"
                            expanded
                            v-model="bloodType"
                          >
                            <option
                              v-for="option in bloodTypes"
                              :value="option"
                              :key="option"
                            >
                              {{ option }}
                            </option>
                          </b-select>
                        </b-field>
                      </ValidationProvider>
                    </div>

                    <div class="column is-6-desktop is-6-tablet is-12-mobile">
                      <ValidationProvider
                        name="Religion"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-field
                          label="Religion"
                          :type="errors[0] && 'is-danger'"
                          :message="errors[0]"
                        >
                          <b-select
                            placeholder="Religion"
                            expanded
                            v-model="religion"
                          >
                            <option
                              v-for="option in religions"
                              :value="option"
                              :key="option"
                            >
                              {{ capitalizeFirstLetter(option) }}
                            </option>
                          </b-select>
                        </b-field>
                      </ValidationProvider>
                    </div>

                    <div class="column is-6-desktop is-6-tablet is-12-mobile">
                      <ValidationProvider
                        name="Contact Number"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-field
                          label="Contact Number"
                          :type="errors[0] && 'is-danger'"
                          :message="errors[0]"
                        >
                          <b-input
                            v-model="contactNumber"
                            placeholder="081122334456"
                            expanded
                          ></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>

                    <div class="column is-12">
                      <ValidationProvider
                        name="Email"
                        v-slot="{ errors }"
                        rules="required|email"
                      >
                        <b-field
                          label="E-mail"
                          :type="errors[0] && 'is-danger'"
                          :message="errors[0]"
                        >
                          <b-input
                            placeholder="johndoe@gmail.com"
                            v-model="email"
                          ></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>

                    <div class="column is-12">
                      <ValidationProvider
                        name="Office Email"
                        v-slot="{ errors }"
                        rules="required|email"
                      >
                        <b-field
                          label="Office E-mail"
                          :type="errors[0] && 'is-danger'"
                          :message="errors[0]"
                        >
                          <b-input
                            disabled
                            placeholder="johndoe@gmail.com"
                            v-model="officeEmail"
                          ></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>

                    <div class="column is-12">
                      <ValidationProvider
                        name="Current Address"
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-field
                          label="Current Address"
                          :type="errors[0] && 'is-danger'"
                          :message="errors[0]"
                        >
                          <b-input
                            v-model="currentAddress"
                            placeholder="jl.Ciawitali 5A, Cimahi"
                          ></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>

                    <div class="column is-12">
                      <ValidationProvider
                        name="Address"
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-field
                          label="Address"
                          :type="errors[0] && 'is-danger'"
                          :message="errors[0]"
                        >
                          <b-input
                            v-model="address"
                            placeholder="jl.Ciawitali 5A, Cimahi"
                          ></b-input>
                        </b-field>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </template>
  </r-page>
</template>

<script>
import { formatDate, showToast } from '../../../services/util'
import { bloodTypes, genders, religions } from '../../../constant'
import ProfilePicture from './ProfilePicture'
import { mapGetters } from 'vuex'
import ApiService from '../../../services/common/api.service'

export default {
  components: { ProfilePicture },
  computed: {
    ...mapGetters({
      currentUser: 'user/getUserInfo',
      isLoadingCurrentUser: 'user/getIsLoading',
      uploadImageProgress: 'user/getUploadImageProgress',
    }),
  },
  watch: {
    currentUser: function () {
      if (this.currentUser != null) {
        this.getPersonalData()
      }
    },
  },
  data() {
    return {
      genders: genders,
      bloodTypes: bloodTypes,
      religions: religions,

      firstName: null,
      middleName: null,
      lastName: null,
      birthPlace: null,
      birthDate: undefined,
      gender: null,
      nationality: null,
      bloodType: null,
      religion: null,
      contactNumber: null,
      email: null,
      officeEmail: null,
      currentAddress: null,
      address: null,

      isLoadingData: false,
      isRequesting: false,
    }
  },
  methods: {
    async save() {
      this.isRequesting = true
      try {
        let params = {
          firstName: this.firstName,
          middleName: this.middleName,
          lastName: this.lastName,
          workEmail: this.officeEmail,
          personalEmail: this.email,
          dateOfBirth: formatDate(this.birthDate, 'YYYY-MM-DD'),
          placeOfBirth: this.birthPlace,
          nationality: this.nationality,
          address: this.address,
          currentAddress: this.currentAddress,
          gender: this.gender,
          personalPhone: this.contactNumber,
          religion: this.religion,
          bloodType: this.bloodType,
          reason: '-',
        }
        await ApiService.post(
          '/api/space-roketin/employee-info/request',
          params
        )
        showToast('Request Success', 'is-success', 'is-top')
        this.$router.push('/settings/personal-info')
      } catch (err) {
        console.log(err)
      }
      this.isRequesting = false
    },
    dateFormatter(dt) {
      return formatDate(new Date(dt), 'LL')
    },
    formatProfilePictureUrl(user) {
      if (user?.profilePictureUrl) {
        return user?.profilePictureUrl
      }

      if (user?.gender === 'M') {
        return '/images/default-profile-picture-male.png'
      }

      if (user?.gender === 'F') {
        return '/images/default-profile-picture-female.png'
      }

      return '/images/default-profile-picture.png'
    },
    async getPersonalData() {
      this.isLoadingData = true
      try {
        let response = await ApiService.get('/api/space-roketin/user/detail', {
          id: this.currentUser.id,
        })
        let data = response.data
        this.fillData(data)
      } catch (err) {
        console.log(err)
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        }
      }
      this.isLoadingData = false
    },
    fillData(data) {
      this.bloodType = data.bloodType
      this.religion = data.religion

      this.firstName = data.firstName
      this.middleName = data.middleName
      this.lastName = data.lastName
      this.birthPlace = data.placeOfBirth
      this.birthDate = new Date(data.dateOfBirth)
      this.gender = data.gender
      this.nationality = data.nationality
      this.contactNumber = data.personalPhone
      this.email = data.personalEmail
      this.officeEmail = data.workEmail
      this.currentAddress = data.currentAddress
      this.address = data.homeAddress
    },
  },
  mounted() {
    if (this.currentUser != null) {
      this.getPersonalData()
    }
  },
}
</script>
